import {lazy} from "react";
const BlocksRender = lazy(() => import(/* webpackChunkName: "BlocksRender" */ "./widgets/BlocksRender"));
//const CmsDesign = lazy(() => import("./views/cmsDesign/CmsDesign"));
const Blocks = lazy(() => import("./views/cmsDesign/Blocks"));

const exports = {
    routes: [
        /*{
            name: "design",
            path: ["dizajn", "design"],
            trans: ["Dizajn", "Design"],
            element: <CmsDesign />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 25}},
        },*/
    ],
    widgets: {
        Blocks: Blocks,
        BlocksRender: BlocksRender
    }
}
export default exports;