import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/_app/App';
import {serviceWorkerHook} from "./modules/_app/serviceWorkerHook";

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

serviceWorkerHook();