import {useNavigate, useParams} from "react-router-dom";
import {useContext} from "react";
import AppContext from "../../_app/AppContext";



function useQueryParser()  {
    const {categoryBySlug} = useContext(AppContext);
    let navigate = useNavigate();
    const {productId, categorySlug, brandName} = useParams();
    let category = categoryBySlug(categorySlug);

    const pathnameArr = window.location.pathname.split("/");
    let pageName = pathnameArr[1];

    const query = window.location.search; // could be '?foo=bar'
    const queryParams =  new URLSearchParams(decodeURIComponent(query));

    let order = queryParams.get("order") ? parseInt(queryParams.get("order")) : undefined;
    let page = queryParams.get("page") ? parseInt(queryParams.get("page")) : undefined;
    const search = queryParams.get("pretraga") ? queryParams.get("pretraga") : undefined;
    let filters = {};
    if(queryParams.get("filters"))  {
        const filtersParams = queryParams.get("filters");
        const split = filtersParams.split("-");
        for(let i=0; i<split.length; i++)   {
            const arr = split[i].split(":");
            if(arr[0] && arr[0])  {
                filters[arr[0]] = arr[1].split(",");
            }
        }
    }

    let brands = [];
    if(queryParams.get("brands"))  {
        const split = queryParams.get("brands").split(",");
        brands = split.map(item => decodeURIComponent(item));
    }

    const link = (field, value) => {
        const string = url(field, value);
        navigate(string);
    }

    const url = (field, value) => {
        let string = "/" + pageName;
        if(brandName) string += "/" + brandName;

        const categoryField = field === "category" ? value : category;
        if(categoryField && categoryField.breadcrumbs)    {
            string += "/" + categoryField.breadcrumbs.map(it => it.slug).join("/");
        }
        if(field === "category")    {
            return string;
        }

        const params = {};
        const orderField = field === "order" ? value : order;
        if(orderField)   {
            params["order"] = orderField;
        }
        const pageField = field === "page" ? value : page;
        if(pageField)   {
            params["page"] = pageField;
        }

        let newBrands = [...brands];
        if(field === "brands")      {
            const checked = value.target.checked;
            const name = value.target.value;
            if(checked) {
                if(!newBrands.includes(name))   {
                    newBrands.push(encodeURIComponent(name));
                }
            } else {
                newBrands = newBrands.filter(it => it !== name);
            }
        }
        if(newBrands.length > 0)    {
            params["brands"] = newBrands.join(",");
        }

        let newFilters = {...filters};
        if(field === "filters")      {
            const checked = value.target.checked;
            const parent = value.target.name;
            const id = value.target.value;
            if(checked) {
                if(!filters[parent])    {
                    newFilters[parent] = [];
                }
                newFilters[parent].push(id);
            }   else  {
                if(filters[parent] && filters[parent].includes(id)) {
                    if(filters[parent].length === 1)    {
                        delete newFilters[parent];
                    } else  {
                        const inner = filters[parent].filter(it => it !== id);
                        newFilters = {...newFilters, [parent]: inner};
                    }
                }
            }
        }
        if(Object.keys(newFilters).length > 0)  {
            let url = [];
            for(let key in newFilters)   {
                url.push(`${key}:${newFilters[key].join(",")}`);
            }
            if(url.length > 0) {
                params["filters"] = url.join("-");
            }
        }

        if(Object.keys(params).length > 0)  {
            string += "?" + Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
        }
        return string;
    }

    return {productId, category, brandName, pageName, page, filters, brands, order, url, link, search};
}
export default useQueryParser;