import {gql} from "@apollo/client";
import {PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";
import {STORAGE_FRAGMENT} from "../../storage/graphql/storage";
import {CATEGORY_FRAGMENT} from "../../categories/graphql/categories";
import {FILTER_FRAGMENT} from "../../filters/graphql/filter";


export const PRODUCT_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    ${CATEGORY_FRAGMENT}
    fragment ProductFragment on Product {
        id
        active
        group_id
        connected_id
        name
        names
        code
        brand
        price
        prices
        discount_price
        discount_prices
        process_price
        action
        new
        qty
        description
        descriptions
        seo_title
        seo_keywords
        seo_description
        video
        seo_titles
        seo_keywords_all
        seo_descriptions
        discount_price_start
        discount_price_end
        categories {
            ...CategoryFragment
        }
        image {
            ...StorageFragment
        }
    }
`;

export const CREATE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($name: String!)  {
        createProduct(name: $name) {
            ...ProductFragment
        }
    }
`

export const GET_PRODUCT_QUERY = "getProduct";
export const GET_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    ${FILTER_FRAGMENT}
    query($id: ID!) {
        getProduct(id: $id) {
            ...ProductFragment
            group {
                ...ProductFragment
            }
            connected {
                ...ProductFragment
            }
            filters {
                ...FilterFragment
            }
            images {
                ...StorageFragment
            }
        }
    }
`


export const GET_PRODUCTS_QUERY = "getProducts";
export const GET_PRODUCTS = gql`
    ${PRODUCT_FRAGMENT} 
    ${PAGINATOR_INFO_FRAGMENT}
    query($first: Int, $page: Int, $search: String, $order: Int, $ids: [ID!],
        $category: [ID], $filters: String, $brands: [String], $price: Float, $brandName: String, $pageName: String) {
        getProducts(first: $first, page: $page, search: $search, order: $order, ids: $ids, brands: $brands,
            category: $category, filters: $filters, price: $price, brandName: $brandName, pageName: $pageName) {
            data {
                ...ProductFragment
                group {
                    ...ProductFragment
                }
            }
            paginatorInfo {
                ...PaginatorInfoFragment
            }
        }
    }
`

export const UPDATE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID!, $position: Int, $name: String, $code: String, $brand: String, $image_id: ID, $active: Boolean,
        $group_id: String, $connected_id: String, $price: Float, $discount_price: Float, $action: Boolean, $new: Boolean, $video: String,
        $description: String, $seo_title: String, $seo_description: String, $seo_keywords: String,
        $discount_price_start: String, $discount_price_end: String,
        $detach_image: ID, $attach_images: [ID], $order_images: [ID],
        $attach_categories: [ID], $detach_categories: [ID], $attach_filters: [ID], $detach_filters: [ID])  {
        
        updateProduct(id: $id, position: $position, name: $name, code: $code, brand: $brand, image_id: $image_id,
            active: $active, group_id: $group_id, connected_id: $connected_id, price: $price, discount_price: $discount_price,
            action: $action, new: $new, description: $description, video: $video,
            seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords,
            discount_price_start: $discount_price_start, discount_price_end: $discount_price_end,
            detach_image: $detach_image, attach_images: $attach_images, order_images: $order_images,
            attach_categories: $attach_categories, detach_categories: $detach_categories,
            attach_filters: $attach_filters, detach_filters: $detach_filters) {
            ...ProductFragment
        }
    }
`

export const DELETE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}
    mutation ($id: ID!) {
        deleteProduct(id: $id) {
            ...ProductFragment
        }
    }
`