import {server} from "../../_app/config";


function storageFile() {
    return server("storage/000-file.png");
}
function noImage() {
    return server("storage/000-no-image.png");
}
export function image(image, lg=false) {
    if (typeof image === "string") {
        const name = lg ? `lg-${image}` : image;
        return server(`storage/slike/${image}`);
    }
    if(!image)  {
        return noImage();
    }
    if(!image.width)    {
        return storageFile();
    }
    const name = lg ? `lg-${image.name}` : image.name;
    return server(`storage/slike/${image.folder}/${name}`);
}
