export function server(path) {
    if(path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5020/${path}`;
    }
    //return `http://localhost:5000/${path}`;
    return `https://taceq.com/${path}`;
}
export function graphQL() {
    return server("graphql");
}
export function ws() {
    return "wss://asortiman.com:8000/ws"
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return "ws://localhost:8000/ws";
    }
    return "wss://asortiman.com:8000/ws"
}
export const roles = {
    1: {name: "Administrator", role: 1},
    2: {name: "Menadžer", role: 2},
    3: {name: "Editor ", role: 3},
}

