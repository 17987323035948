import {lazy} from "react";
import WishlistProductButton from "./widgets/WishlistProductButton";
import WishlistButton from "./widgets/WishlistButton";
import PreloadWishlistWrapper from "./preloads/PreloadWishlistWrapper";
const Wishlist = lazy(() => import("./views/wishlist/Wishlist"));

const exports = {
    routes: [
        {
            name: "wishlist",
            path: ["omiljeno", "wishlist"],
            element: <Wishlist />,
            layouts: ["public.PublicLayout"]
        },
    ],
    preloads: [PreloadWishlistWrapper],
    widgets: {
        WishlistProductButton: WishlistProductButton,
        WishlistButton: WishlistButton,
    }
}
export default exports;