import {lazy} from "react";
import PreloadSettingsWrapper from "./preloads/PreloadSettingsWrapper";
const Settings = lazy(() => import("./views/settings/Settings"));
const UpdateField = lazy(() => import("./widgets/UpdateField"));

const exports = {
    routes: [
        {
            name: "settings",
            path: ["podesavanja", "settings"],
            trans: ["Podešavanja", "Settings"],
            element: <Settings />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 30, roles: [1,2]}},
        },
    ],
    preloads: [PreloadSettingsWrapper],
    widgets: {
        UpdateField: UpdateField
    }
}
export default exports;