import {Toaster} from "react-hot-toast";
import Router from "./Router";
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "./apolloClient";
import {modulesPreloads, widget} from "../modules";
import {randString} from "../../elements/helpers";


function App() {

    const checkClientCode = () => {
        let code = localStorage.getItem("client");
        if(!code)   {
            const abcd = ['A', 'D', 'f', 'T', 'q', 'M', 'c', 'S', 'o', 'P'];
            const unique = Date.now().toString();
            code = randString(4);
            for (var i = 0; i < unique.length; i++) {
                code = code + abcd[unique.charAt(i)];
            }
            localStorage.setItem("client", code);
        }
        return code;
    }

    const context = {
        client: checkClientCode(),
        admin: widget("cms.providerCheckAdmin", null),
        user: widget("users.providerCheckUser", null),
        lang: 0
    };
    const client = apolloClient(context);

    const routerWrappers = [...modulesPreloads, Router];
    routerWrappers.map((item, i) => item.defaultProps = {Next: routerWrappers[i+1] || null});
    const First = routerWrappers[0];

    return (
        <ApolloProvider client={client}>
            <Toaster />

            <First context={context} />
        </ApolloProvider>
    );
}

export default App;
