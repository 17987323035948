import {lazy} from "react";
const Breadcrumbs = lazy(() => import("./widgets/Breadcrumbs"));
const PublicLayout = lazy(() => import("./views/layout/PublicLayout"));
const Error404 = lazy(() => import("./views/error404/Error404"));
const Contact = lazy(() => import("./views/contact/Contact"));


const exports = {
    routes: [
        {name: "PublicLayout", path: "/", element: <PublicLayout />},
        {name: "error404", path: ["*"], element: <Error404 />, layouts: ["public.PublicLayout"]},
        {name: "contact", path: ["/kontakt", "/contact"], element: <Contact />, layouts: ["public.PublicLayout"]},

],
    widgets: {
        Error404: Error404,
        Breadcrumbs: Breadcrumbs
    },
}
export default exports;
