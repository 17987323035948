import {gql} from "@apollo/client";


export const PAGE_FRAGMENT = gql`
    fragment PageFragment on Page { 
        id 
        menu
        type
        page
        block_code
        category_id
        brand_name
        active
        name
        slug
        names
        slugs 
        seo_title
        seo_keywords
        seo_description
        seo_titles
        seo_keywords_all
        seo_descriptions
        locked
    }
`;

export const GET_PAGES_QUERY = "getPages";
export const GET_PAGES = gql`
    ${PAGE_FRAGMENT}
    query ($search: String) {
        getPages(search: $search) {
            ...PageFragment
        }
    }
`

export const CREATE_PAGE = gql` 
    ${PAGE_FRAGMENT}
    mutation ($name: String!, $slug: String!, $menu: Int!)  {
        createPage(name: $name, slug: $slug, menu: $menu) {
            ...PageFragment
        }
    }
`
export const UPDATE_PAGE = gql` 
    ${PAGE_FRAGMENT}
    mutation ($id: ID!, $name: String, $slug: String, $names: [String], $slugs: [String], $active: Boolean,
        $menu: Int, $type: Int, $block_code: String, $category_id: ID, $brand_name: String,
     $seo_title: String, $seo_description: String, $seo_keywords: String)  {
        updatePage(id: $id, name: $name, slug: $slug, names: $names, slugs: $slugs, active: $active,
            menu: $menu, type:$type, block_code: $block_code, category_id: $category_id, brand_name: $brand_name,
            seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords) {
            ...PageFragment
        }
    }
`

export const DELETE_PAGE = gql`
    ${PAGE_FRAGMENT}
    mutation ($id: ID!) {
        deletePage(id: $id) {
            ...PageFragment
        }
    }
`
