import {modulesRoutes} from "../../modules";
import {translations} from "../config";


function LangHooksWrapper({Next, context}) {

    const route = (path, params) => {
        if(path === "index")    {
            return ["/", "/home"][context.lang];
        }
        if(!modulesRoutes[path])       {
            console.log("MISSING: ", `Ne postoji route: ${path}`);
            return "";
        }
        const compile = modulesRoutes[path].compile;
        if(compile)  {
            return compile(params, context.lang);
        }
        const r = modulesRoutes[path].fullpath;
        if (typeof r === 'string' || r instanceof String)   {
            return r;
        }
        if(!r[context.lang])   {
            console.log("MISSING: ", `Ne postoji route lang: ${path} lang: ${context.lang}`)
            return r[0];
        }
        return r[context.lang];
    }

    const trans = (name) => {
        if(Array.isArray(name))  {
            if(!name[context.lang]) {
                return name[0];
            }
            return name[context.lang];
        }

        if(!translations[name]) {
            console.log("MISSING: ", `Ne postoji trans za: "${name}" lang: ${context.lang}`)
            return "*"+name;
        }
        if(!translations[name][context.lang]) {
            return name;
        }
        return translations[name][context.lang];
    }

    return <Next context={{...context, route, trans}} />
}
export default LangHooksWrapper;