
const urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/\\-/g, '+').replace(/_/g, '/')
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
};

export const serviceWorkerHook = async () => {
    if (!('serviceWorker' in navigator)) {
        return null;
    }
    const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;
    return await navigator.serviceWorker.register(swUrl);
};

export const serviceWorkerSubscribe = () => {
    return serviceWorkerHook().then(registration => {
        const applicationServerKey = urlB64ToUint8Array(
            'BPLcVnAo0slsiAEMebFCZo9QCD_uKZ4G03VQL70TIMFG7Gp_Ri_P6gppZFEgTUUBNf6Rw45Aa2hPiOabBuhXd9s'
        );
        const options = {applicationServerKey, userVisibleOnly: true};
        return registration.pushManager.subscribe(options);
    });
}
