import {useContext} from "react";
import AppContext from "../../../_app/AppContext";
import {widget} from "../../../modules";




function PageTemplate() {
    const {currentRoute} = useContext(AppContext);

    if(currentRoute.page?.block_code)  {
        return <div>
            {widget("design.BlocksRender", {code: currentRoute.page?.block_code})}
        </div>
    }

    return null
}
export default PageTemplate;