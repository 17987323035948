import {lazy} from "react";
import LoadPagesWrapper from "./preloads/LoadPagesWrapper";
const CmsPage = lazy(() => import("./views/cmsPages/CmsPage"));


const exports = {
    routes: [
        {
            name: "cmsPage",
            trans: ["Stranice", "Pages"],
            path: ["stranice", "pages"],
            element: <CmsPage />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 30}},
            compile: (id, lang) => {
                if(!id) return `/cms/${["stranice", "pages"][lang]}`;
                return `/cms/${["stranice", "pages"][lang]}/${id}`;
            }
        },
        {name: "cmsPage2", path: ["stranice/:id", "pages/:id"], element: <CmsPage />, layouts: ['cms.CmsLayout']},
    ],
    preloads: [LoadPagesWrapper],
}
export default exports;
