import {lazy} from "react";
import LangMatchWrapper from "./preloads/LangMatchWrapper";
import LangHooksWrapper from "./preloads/LangHooksWrapper";
const Wrapper = lazy(() => import("./widgets/Wrapper"));
const LangSelect = lazy(() => import("./widgets/LangSelect"));

const exports = {
    routes: [

    ],
    preloads: [LangMatchWrapper, LangHooksWrapper],
    widgets: {
        LangSelect: LangSelect,
        Wrapper: Wrapper,
    }
}
export default exports;