import rsFlag from "./images/rs-flag.png";
import usFlag from "./images/us-flag.png";

export const languages = [
    {
        id: 0,
        short: "RS",
        name: "Srpski",
        value: "/",
        label: <span><img src={rsFlag} width={20} alt="RS zastava"/> RS </span>
    },
    {
        id: 1,
        short: "EN",
        name: "English",
        value: "/home",
        label: <span><img src={usFlag} width={20} alt="US zastava"/> EN </span>
    }
];

export const translations = {
    "ne postoji stranica": ["Ne postoji stranica.", "Page doesn't exists."],
    "zatvorite": ["Zatvorite", "Close"],
    "sacuvajte": ["Sačuvajte", "Save"],
    "sacuvano": ["Sačuvano!", "Saved!"],
    "pretraga": ["Pretraga", "Search"],
    "podesavanja": ["Podešavanja", "Settings"],
    "prazna pretraga": ["Prazna pretraga", "Empty search"],
    "currency": ["RSD", "EUR"],
    "nalog": ["Nalog", "Account"],
    "polje": ["Polje", "Field"],

    "naslovna": ["Naslovna", "Home"],
    "kontakt": ["Kontakt", "Contact"],
    "detaljnije": ["Detaljnije", "Details"],
    "obrisite": ["Obrišite", "Delete"],
    "zelite da obrisete": ["Želite da obrišete?", "Do you want to delete?"],

    /* CMS */
    "ulogujte se": ["Ulogujte se", "Login"],
    "registrujte se": ["Registrujte se", "Register"],
    "registrujte nalog": ["Registrujte nalog", "Register account"],
    "zapamti me": ["Zapamti me", "Remember me"],
    "unesite vas email": ["Unesite vaš email", "Enter your email"],
    "posaljite": ["Pošaljite", "Send"],
    "poslat email za reset lozinke": ["Poslat email za reset lozinke.", "Sent email for link reset."],
    "idite na sajt": ["Idite na sajt", "Go to website"],
    "izlogujte se": ["Izlogujte se", "Logout"],
    "zaboravili ste lozinku": ["Zaboravili ste lozinku?", "Forgot your password?"],

    "dodajte administratora": ["Dodajte administratora", "Add administrator"],
    "zelite da obrisete administratora": ["Želite da obrišete administratora?", "Want to delete administrator?"],
    "obrisano": ["Obrisano!", "Deleted!"],
    "ime": ["Ime", "Name"],
    "prezime": ["Prezime", "Lastname"],
    "lozinka": ["Lozinka", "Password"],
    "nova lozinka": ["Nova Lozinka", "New Password"],
    "resetujte lozinku": ["Resetujte lozinku", "Reset password"],
    "posaljite link": ["Pošaljite link", "Send link"],
    "poslato": ["Poslato!", "Sent!"],
    "posaljite link za reset lozinke": ["Pošaljite link za reset lozinke na email", "Send link for password reset on email"],
    "zelite da posaljete email": ["Želite da pošaljete email?", "Want to send email?"],

    "sve": ["Sve", "All"],
    "brend": ["Brend", "Brand"],
    "kategorija": ["Kategorija", "Category"],
    "grupa": ["Grupa", "Group"],
    "popust cena": ["Popust cena", "Discount price"],
    "popust": ["Popust", "Discount"],
    "cena za prikaz": ["Cena za prikaz", "Display price"],
    "nije postavljeno": ["nije postavljeno", "not set"],
    "popust pocinje": ["Popust počinje", "Discount starts"],
    "popust se zavrsava": ["Popust se završava", "Discount ends"],
    "slike": ["Slike", "Images"],
    "fajlovi": ["Fajlovi", "Files"],
    "dodajte fajl": ["Dodajte fajl", "Add File"],
    "kliknite da dodate fajl": ["Kliknite da dodate fajl", "Click to add file"],
    "blok pocinje": ["Blok počinje", "Block starts"],
    "blok se zavrsava": ["Blok se završava", "Block ends"],
    "kontakt informacije": ["Kontakt informacije", "Contact informations"],

    "cookies title": ["Ovaj veb sajt koristi kolačiće", "Овај веб сајт користи колачиће", "This website uses cookies"],
    "cookies desc": [
        "Koristimo kolačiće za personalizaciju sadržaja, pružanje funkcija društvenih medija i analiziranje saobraćaja. Ako nastavite da koristite naše veb-stranice, saglasni ste sa korišćenjem naših kolačića.",
        "Користимо колачиће за персонализацију садржаја, пружање функција друштвених медија и анализирање саобраћаја. Ако наставите да користите наше веб-странице, сагласни сте са коришћењем наших колачића.",
        "We use cookies to personalize content, to provide functions social media and traffic analysis. If you continue to use our website, you agree to the use of our cookies."
    ],
    "prihvatam": ["PRIHVATAM", "ПРИХВАТАМ", "ACCEPT"],


    "socijalne mreze": ["Socijalne mreže", "Social networks"],
    "dodajte proizvod": ["Dodajte proizvod", "Add new product"],
    "dodajte proizvode": ["Dodajte proizvode", "Add new products"],
    "naziv proizvoda": ["Naziv proizvoda", "Product name"],
    "na vrh": ["Na vrh", "To Top"],
    "deaktivirajte": ["Deaktivirajte", "Deactivate"],
    "aktivirajte": ["Aktivirajte", "Activate"],
    "dodajte vest": ["Dodajte vest", "Add new"],
    "naslov": ["Naslov", "Title"],
    "pratite nas": ["Pratite nas", "Follow us"],
    "preuzmite": ["Preuzmite", "Download"],
    "zatrazite ponudu": ["Zatražite ponudu, ili zakažite gratis konsultacije", "Request an offer or schedule a free consultation"],
    "obrazac za identifikaciju": ["Obrazac za identifikaciju", "Identification form"],

    "dodajte stranu": ["Dodajte stranu", "Add page"],
    "dodajte blok": ["Dodajte blok", "Add block"],
    "dodajte kategoriju": ["Dodajte kategoriju", "Add category"],
    "blok je aktiviran": ["Blok je aktiviran", "Block is activated"],
    "blok je deaktiviran": ["Blok je deaktiviran", "Block is deactivated"],
    "zelite da obrisete blok": ["Želite da obrišete blok?", "Want to delete block?"],
    "zelite da obrisete baner": ["Želite da obrišete baner?", "Want to delete banner?"],
    "broj banera u jednom redu": ["Broj banera u jednom redu", "Number of banners in a row"],
    "ako je prazno, svi baneri su u jednom redu": ["ako je prazno, svi baneri su u jednom redu", "if blank, all banners are in one line"],
    "prikazuj kao carousel": ["Prikazuj kao carousel", "Show as carousel"],
    "broj proizvoda u jednom redu": ["Broj proizvoda u jednom redu", "Number of products in a row"],
    "broj kategorija u jednom redu": ["Broj kategorija u jednom redu", "Number of categories in a row"],
    "unesite tekst": ["Unesite tekst", "Enter text"],
    "dodajte baner": ["Dodajte baner", "Add banner"],
    "baner nije aktiviran i nece se prikazivati": ["Baner nije aktiviran i nece se prikazivati.", "The banner is not activated and will not be displayed."],
    "mobilni": ["MOBILNI", "MOBILE"],
    "opis slike": ["Opis (alt) slike.", "Image description (alt)."],
    "link slike": ["Link slike.", "Image link."],
    "prikazuj na mobilnom": ["Prikazuj na mobilnom", "Show on mobile."],
    "baner je aktiviran": ["Baner je aktiviran", "Banner is activated"],
    "baner je deaktiviran": ["Baner je deaktiviran", "Banner is deactivated"],
    "otvaraj u novom prozoru": ["Otvaraj u novom prozoru", "Open in new window"],
    "zelite da uklonite sliku": ["Zelite da uklonite sliku?", "Want to remove the image?"],
    "uklonite": ["uklonite", "remove"],
    "kliknite na sliku da promenite": ["Kliknite na sliku da promenite", "Click on the image to change"],

    "otvorite sve": ["Otvorite sve", "Open all"],
    "zatvorite sve": ["Zatvorite sve", "Close all"],
    "predmet": ["Predmet", "Subject"],
    "poruka": ["Poruka", "Message"],
    "zelite da obrisete kategoriju": ["Želite da obrišete kategoriju?", "Do you want to delete the category?"],
    "prevucite da promenite raspored": ["Prevucite da promenite raspored.", "Drag to change the order."],

    "naziv": ["Naziv", "Name"],
    "dodajte": ["Dodajte", "Add"],
    "kategoriju": ["kategoriju", "category"],
    "kategorije": ["Kategorije", "Categories"],

    "brendovi": ["Brendovi", "Brands"],
    "dodajte brend": ["Dodajte brend", "Add brand"],
    "dodajte filter": ["Dodajte filter", "Add filter"],
    "odaberi": ["Odaberi", "Select"],
    "akcija": ["Akcija", "Action"],
    "novo": ["Novo", "New"],

    "osnovno": ["Osnovno", "Informations"],
    "sifra": ["Šifra", "Code"],
    "datum": ["Datum", "Date"],
    "ime i prezime": ["Ime i prezime", "Full name"],
    "telefon": ["Telefon", "Phone"],
    "odgovorite": ["Odgovorite", "Reply"],
    "kuca": ["kuca...", "typing..."],
    "kontakirajte nas": ["Kontakirajte nas", "Contact us"],
    "unesite pitanje": ["Unesite pitanje...", "Insert question..."],
    "proizvod vise nije dostupan": ["proizvod više nije dostupan", "product is removewd"],


    /*
        PUBLIC
     */
    "pogledajte ponudu": ["POGLEDAJTE PONUDU", "SEE THE OFFER"],
    "mapa sajta": ["MAPA SAJTA", "SITE MAP"],
    "kupovina": ["KUPOVINA", "FAQ"],
    "podrska": ["PODRŠKA", "SUPPORT"],
    "dostava": ["Dostava", "Delivery"],
    "omiljeno": ["Omiljeno", "Wishlist"],
    "korpa": ["Korpa", "Basket"],
    "strane": ["Strane", "Pages"],
    "house": ["Kuća", "HOUSE"],
    "bilo gde u svetu": ["Bilo gde u svetu", "Anywhere in the world"],
    "vrhunskih taktickih brendova": ["Vrhunskih taktičkih brendova", "Of top-rated tactical brands"],
    "povracaj robe": ["POVRAĆAJ ROBE", "RETURN GOODS"],
    "14 dana od dana prijema": ["14 dana od dana prijema", "14 days from the date of admission"],
    "nema proizvoda": ["Nema proizvoda.", "No products."],
    "sve kategorije": ["Sve kategorije", "All categories"],
    "od": ["od", "of"],
    "proizvoda": ["proizvoda", "products"],
    "sortiraj": ["SORTIRAJ", "SORT"],
    "po relevantnosti": ["Po relevantnosti", "By relevance"],
    "po nazivu": ["Po nazivu", "By name"],
    "filteri": ["Filteri", "Filters"],
    "boja": ["Boja", "Colors"],
    "povezani proizvodi": ["Povezani proizvodi", "Connected products"],
    "poslednje pogledano": ["Poslednje pogledano", "Last viewed"],
    "po ceni rastuce": ["Po ceni rastuće", "By price ascending"],
    "po ceni opadajuce": ["Po ceni opadajuće", "By price descending"],

    "dodajte u korpu": ["Dodajte u korpu", "Add to cart"],
    "dodato u korpu": ["Dodato u korpu", "Added to cart"],
    "korpa je prazna": ["Korpa je prazna", "Cart is empty"],
    "ukupno": ["Ukupno", "Total"],
    "zavrsite porudzbinu": ["Završite porudžbinu", "Finish order"],

    "br": ["Br.", "No."],
    "slika": ["Slika", "Image"],
    "artikal": ["Artikal", "Item"],
    "informacije za porucivanje": ["Informacije za poručivanje", "Informations"],
    "obavezna polja": ["Polja označena zvezdicom (*) su obavezna.", "Fields marked with an asterisk (*) are required."],
    "postanski broj": ["Poštanski broj", "Area code"],
    "tabela velicina": ["Tabela veličina", "Size chart"],
    "placanje pouzecem": ["Plaćanje pouzećem", "Cash on delivery"],
    "uplata na tekuci racun": ["Uplata na tekući račun", "Payment to current account"],
    "placanje 1 desc": ["Plaćanje kurirskoj službi prilikom preuzimanja paketa", "Payment to the courier service when picking up the package"],
    "placanje 2 desc": [
        "Nakon što završite proces naručivanja kontaktiraće vas neko od našeg osoblja",
        "Once you have completed the ordering process, you will be contacted by one of our staff"],
    "posalji porudzbinu": ["POŠALJI PORUDŽBINU", "SUBMIT ORDER"],
    "dostava desc": [
        "Dostava se vrši na teritoriji Republike Srbije, preko kurirske službe \"Dexpress\" i naplaćuje se po cenovniku:\n" +
        "\n" +
        "do 1 kg - 390,00 rsd\n" +
        "od 1 do 2 kg - 490,00 rsd\n" +
        "od 2 do 5 kg - 590,00 rsd\n" +
        "od 5 do 10 kg - 690,00 rsd\n" +
        "od 10 do 20 kg - 890,00 rsd\n" +
        "\n" +
        "Cene su sa uračunatim PDV. Troškovi dostave će biti istaknuti na računu kao posebna stavka u skladu sa Zakonom RS.\n" +
        "\n" +
        "Za porudžbine preko 8000 rsd, poštarina je besplatna!",
        "You can pay via bank wire transfer or PayPal. If all goods are in stock, you will receive a Proforma Invoice with the prices of the goods, transport costs and payment instructions. The buyer shall bear and pay the full cost, including all banking charges."],
    "uspesno ste porucili": ["Uspešno ste poručili!", "You have successfully ordered!"],
    "hvala na poverenju": ["Hvala na poverenju!", "Thank you for your trust!"],
    "cena": ["Cena", "Price"],
    "porucena cena": ["Poručena cena", "Ordered price"],
    "kolicina": ["Količina", "Quantity"],
    "zemlja": ["Zemlja", "Country"],
    "adresa": ["Adresa", "Address"],
    "vazni linkovi": ["VAŽNI LINKOVI", "Links"],
    "grad": ["Grad", "City"],
    "dodatna napomena": ["Dodatna napomena", "Extra message"],
    "nacin placanja": ["Način plaćanja", "Payment method"],
    "promo kod": ["Promo kod", "Promo code"],
    "unesite promo kod": ["Unesite promo kod", "Enter promo code"],
    "proverite kod": ["Proverite kod", "Check code"],
    "nije unet promo kod": ["Nije unet promo kod.", "Promo code not entered."],
    "proizvod trenutno nema na stanju": ["Proizvod trenutno nema na stanju.", "The product is currently out of stock."],

    "newsletter desc": [
        "Želim da se prijavim na mailing listu i primam buduća obaveštenja",
        "I want to sign up for the mailing list and receive future notifications",
    ]
};